import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FirebaseError } from '@angular/fire/app';
import {
  Auth,
  createUserWithEmailAndPassword,
  idToken,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateProfile,
  User,
  user,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { from, Observable, of, switchMap } from 'rxjs';

import { UpdateUserSubscriptionDto, UserDto } from '../api';
import { UserService } from '../api/api/user.service';
import { UserPromoCodesService } from '../api/api/user-promo-codes.service';
import { apiConfiguration } from '../credentials';
import { LanguageService } from '../locales';
import { ErrorService } from '../shared/error.service';

export const PROMO_CODE_STORAGE_KEY = 'hh-registration-promo-code';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  static redirectAfterLogin: string | null = null;
  private auth = inject(Auth);
  currentUser$: Observable<User | null> = user(this.auth);
  firebaseUser = toSignal(this.currentUser$);
  private idToken = toSignal(idToken(this.auth));
  private idTokenResult = toSignal(
    this.currentUser$.pipe(switchMap((u) => (u ? from(u.getIdTokenResult()) : of(null)))),
  );
  userRoles = computed(() => (this.idTokenResult()?.claims['roles'] as string[]) ?? []);
  isAdmin = computed(() => this.userRoles().includes('admin'));
  private router = inject(Router);
  private languageService = inject(LanguageService);
  private userService = inject(UserService);
  private errorService = inject(ErrorService);
  private promoCodeService = inject(UserPromoCodesService);
  user = signal<UserDto | null>(null);

  constructor() {
    effect(() => (this.auth.languageCode = this.languageService.language()));
    effect((onCleanup) => {
      const idToken = this.idToken();
      if (idToken) {
        apiConfiguration.credentials = {
          firebase: idToken,
        };
        const sub = this.userService.getUser().subscribe(this.user.set);
        onCleanup(() => sub.unsubscribe());
      } else {
        apiConfiguration.credentials = {};
      }
    });
    effect(() => {
      const user = this.user();
      if (user) {
        const promoCode = localStorage.getItem(PROMO_CODE_STORAGE_KEY);
        if (promoCode) {
          this.promoCodeService.applyPromoCode({ promo_code: promoCode }).subscribe({
            next: this.user.set,
            error: (err) => this.errorService.showErrorDialog(this.errorService.getMessage(err)),
          });
          localStorage.removeItem(PROMO_CODE_STORAGE_KEY);
        }
      }
    });
  }

  async signIn(email: string, password: string) {
    await signInWithEmailAndPassword(this.auth, email, password);
    await this.router.navigateByUrl(AuthenticationService.redirectAfterLogin ?? '/');
    AuthenticationService.redirectAfterLogin = null;
  }

  async register(data: { displayName: string; email: string; password: string }) {
    try {
      const user = await createUserWithEmailAndPassword(this.auth, data.email, data.password);
      await updateProfile(user.user, { displayName: data.displayName });
      await sendEmailVerification(user.user, { url: window.location.origin });
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        if (e.status === HttpStatusCode.Conflict) {
          throw new FirebaseError('auth/email-already-exists', 'The user with the provided email already exists');
        }
      }
      throw e;
    }
    await this.router.navigateByUrl('/');
  }

  async signOut() {
    await this.auth.signOut();
    this.router.navigate(['/']);
  }

  updateSubscription(data: UpdateUserSubscriptionDto) {
    this.userService.updateCurrentSubscription(data).subscribe({
      next: (data) => this.user.set(data),
      error: (error) => this.errorService.showErrorDialog(this.errorService.getMessage(error)),
    });
  }
}
